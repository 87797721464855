import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.js';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import Parallax from 'parallax-js';
import DSGVOWrapper from '@components/dsgvo-wrapper.js';

import 'bootstrap/dist/css/bootstrap.css';
import '@css/pages/outcomes.pcss';
import '@css/photoswipe.pcss';

(() => {
  /* Photoswipe Lightbox */
  const lightbox = new PhotoSwipeLightbox({
    gallerySelector: 'article',
    childSelector: 'a.js-jpt-gallerie-item',
    pswpModule: () => import('photoswipe'),
  });
  lightbox.init();

  /* Accordion Öffnung, bei Klicke von "Alle öffnen" */
  function toggle(event) {
    const checkboxes = document.getElementsByName(
      this.parentElement.parentElement.parentElement.id
    );

    for (let i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = this.checked;
    }
  }

  let jptAccordionInput = document.getElementsByClassName('accordion-all');
  for (let i = 0; i < jptAccordionInput.length; i++) {
    jptAccordionInput[i].addEventListener('click', toggle, false);
  }

  /*
  Erhöhter Kontrast
  Im CSS wurden die Eigenschaften für -ms-high-contrast bzw. forced-colors definiert
  Trotzdem ist es ein recht buntes Design, weshalb allgemein die Möglichkeit bestehen soll,
  den Kontrast zu erhöhen.
  */
  const contrastBtn = document.getElementById('js-jpt-contrast');
  if (contrastBtn) {
    if (getContrastCookie() === 'true') {
      document.body.classList.add('contrast');
    }

    contrastBtn.addEventListener('click', function () {
      if (document.body.classList.contains('contrast')) {
        document.body.classList.remove('contrast');
        setContrastCookie(false);
      } else {
        document.body.classList.add('contrast');
        setContrastCookie(true);
      }
    });
  }

  function setContrastCookie(value) {
    const d = new Date();
    d.setTime(d.getTime() + 86400000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = 'contrast' + '=' + value + ';' + expires + ';path=/';
  }

  function getContrastCookie() {
    const name = 'contrast=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }

    return '';
  }

  // Intro Parallax Initialisierung
  const scene = document.getElementById('js-jpt-intro-scene');
  const parallaxInstance = scene ? new Parallax(scene) : '';

  if (scene) {
    const images = scene.getElementsByTagName('img');
    const len = images.length;
    let loaded = 0;

    for (let i = 0; i < len; i++) {
      if (images[i].complete) {
        loaded = loaded + 1;
      } else {
        images[i].addEventListener('load', function () {
          loaded = loaded + 1;
          if (loaded === len) {
            scene.parentElement.classList.remove('loading');
            scene.parentElement.classList.add('play');
          }
        });
      }
      if (loaded === len) {
        scene.parentElement.classList.remove('loading');
        scene.parentElement.classList.add('play');
      }
    }
  }

  // Scroll Navigation für Mobilgeräte
  const jptNext = document.getElementById('js-jpt-next');
  const jptPrev = document.getElementById('js-jpt-prev');
  const jptScroll = document.getElementById('js-jpt-wrapper');
  let jptScrollJump = false;

  // Die Navigation wird nur auf der Übersichtsseite ausgeführt
  if (jptNext && jptPrev) {
    jptNext.onclick = function () {
      next(jptScroll);
    };
    jptPrev.onclick = function () {
      prev(jptScroll);
    };

    // More button based on scroll
    const jptMore = document.getElementById('js-jpt-slider-more');

    // Background color based on scroll
    const sectionCount = jptScroll.childElementCount - 1;

    // Setzen der Startfarbe und -Links
    let sectionIndex = jptScroll.scrollLeft / jptScroll.clientWidth;
    let colorStart = getComputedStyle(
      document.getElementById('js-jpt-wrapper').children.item(sectionIndex)
    ).getPropertyValue('--jpt-section-bg_s');
    let colorEnd = getComputedStyle(
      document.getElementById('js-jpt-wrapper').children.item(sectionIndex)
    ).getPropertyValue('--jpt-section-bg_e');
    let jptMoreHref = document
      .getElementById('js-jpt-wrapper')
      .children.item(sectionIndex)
      .getElementsByClassName('btn')
      .item(0)
      ? document
          .getElementById('js-jpt-wrapper')
          .children.item(sectionIndex)
          .getElementsByClassName('btn')
          .item(0).href
      : '#';

    document.body.style.setProperty('--body_bg_s', colorStart);
    document.body.style.setProperty('--body_bg_e', colorEnd);
    jptMore.href = jptMoreHref;

    document
      .getElementById('js-jpt-wrapper')
      .addEventListener('scroll', function () {
        let sectionIndexLocal =
          Math.round((jptScroll.scrollLeft / jptScroll.clientWidth) * 100) /
          100;
        sectionIndexLocal =
          sectionIndexLocal % 1 < 0.05
            ? Math.round((jptScroll.scrollLeft / jptScroll.clientWidth) * 10) /
              10
            : sectionIndexLocal;

        // Wenn es ein voller Scroll ist, also eine Sektion aktiv ist, dann wird der sectionIndex gesetzt und die Farben als default gespeichert (aber noch nicht ins CSS gebracht)
        if (sectionIndexLocal % 1 === 0 || sectionIndexLocal > sectionCount) {
          sectionIndexLocal =
            sectionIndexLocal > sectionCount ? sectionCount : sectionIndexLocal;
          sectionIndex = sectionIndexLocal;
          jptScrollJump = false;

          // Farbe setzen bei vollem Scroll
          colorStart = getComputedStyle(
            document
              .getElementById('js-jpt-wrapper')
              .children.item(sectionIndexLocal)
          ).getPropertyValue('--jpt-section-bg_s');
          colorEnd = getComputedStyle(
            document
              .getElementById('js-jpt-wrapper')
              .children.item(sectionIndexLocal)
          ).getPropertyValue('--jpt-section-bg_e');

          // Link setzen
          jptMoreHref = document
            .getElementById('js-jpt-wrapper')
            .children.item(sectionIndex)
            .getElementsByClassName('btn')
            .item(0)
            ? document
                .getElementById('js-jpt-wrapper')
                .children.item(sectionIndex)
                .getElementsByClassName('btn')
                .item(0).href
            : '#';
          jptMore.href = jptMoreHref;
        }

        // Hiermit wird der Scrollweite (wie viele Slides wird weitergeblättert) und die Richtung (- oder +) gesetzt;
        let scroll = sectionIndex - sectionIndexLocal;
        let scrollDirection =
          scroll >= 0 ? -Math.ceil(scroll) : -Math.floor(scroll);
        let status = Math.abs(scroll % 1);

        // Setzen des Index des nächsten Slides, addieren von aktuellem Index mit der Veränderung
        let sectionIndexNext = sectionIndex + scrollDirection;
        sectionIndexNext =
          sectionIndexNext < 0 ? sectionCount : sectionIndexNext;
        sectionIndexNext =
          sectionIndexNext > sectionCount ? sectionCount : sectionIndexNext;

        // Der Jump von 0 zum letzten und andersherum wird durch den Button iniziiert, das wird mit der Variable jptScrollJump kontrolliert
        if (sectionIndex === 0 && jptScrollJump) {
          sectionIndexNext = sectionCount;
        } else if (sectionIndex === sectionCount && jptScrollJump) {
          sectionIndexNext = 0;
        }

        // Bestimmung der nächsten Farbe
        const colorNextStart = getComputedStyle(
          document
            .getElementById('js-jpt-wrapper')
            .children.item(sectionIndexNext)
        ).getPropertyValue('--jpt-section-bg_s');
        const colorNextEnd = getComputedStyle(
          document
            .getElementById('js-jpt-wrapper')
            .children.item(sectionIndexNext)
        ).getPropertyValue('--jpt-section-bg_e');

        // Berechnung der Zwischenfarbe
        const betweenColor = middleColor(colorNextStart, colorNextEnd, status);

        // Setzen der aktuellen Farbe
        document.body.style.setProperty('--body_bg_s', betweenColor[0]);
        document.body.style.setProperty('--body_bg_e', betweenColor[1]);
      });
  }

  function next(jptScroll) {
    const scroll = jptScroll.clientWidth;
    const scrollLeft = jptScroll.scrollLeft;
    const scrollReal = Math.round(scrollLeft / scroll) * scroll + scroll;
    const scrollMax = jptScroll.scrollWidth;

    if (scrollLeft < scrollMax - scroll - 10) {
      jptScroll.scrollTo({ left: scrollReal, behavior: 'smooth' });
    } else {
      jptScroll.scrollTo({ left: 0, behavior: 'smooth' });
      jptScrollJump = true;
    }
  }

  function prev(jptScroll) {
    const scroll = jptScroll.clientWidth;
    const scrollLeft = jptScroll.scrollLeft;
    const scrollReal = Math.round(scrollLeft / scroll) * scroll - scroll;
    const scrollMax = jptScroll.scrollWidth;

    jptScroll.scrollBy({ left: -scroll, behavior: 'smooth' });

    if (jptScroll.scrollLeft > 0) {
      jptScroll.scrollTo({ left: scrollReal, behavior: 'smooth' });
    } else {
      jptScroll.scrollTo({ left: scrollMax - scroll, behavior: 'smooth' });
      jptScrollJump = true;
    }
  }

  function middleColor(colorNextStart, colorNextEnd, status) {
    const colorStartRGB = rgbToArray(colorStart);
    const colorEndRGB = rgbToArray(colorEnd);
    const colorNextStartRGB = rgbToArray(colorNextStart);
    const colorNextEndRGB = rgbToArray(colorNextEnd);

    const middleStartRGB = colorNextStartRGB.map(function (item, index) {
      return Math.round(
        parseInt(colorStartRGB[index]) + status * (item - colorStartRGB[index])
      );
    });

    const middleEndRGB = colorNextEndRGB.map(function (item, index) {
      return Math.round(
        parseInt(colorEndRGB[index]) + status * (item - colorEndRGB[index])
      );
    });

    return [middleStartRGB, middleEndRGB];
  }

  function rgbToArray(rgb) {
    rgb = rgb.replace(/[^\d,]/g, '').split(',');

    return [rgb[0], rgb[1], rgb[2]];
  }

  document.querySelectorAll('.dsgvo-wrapper').forEach(function (wrapper) {
    new DSGVOWrapper(wrapper);
  });
})();
