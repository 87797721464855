/**
 * Der DSGVO-Wrapper ist eine zusätzliche Schicht vor externen Inhalten, um den Datenschutz für die Besucher zu gewährleisten.
 */
export default function DSGVOWrapper(wrapper) {
  wrapper.querySelector('.dsgvo-wrapper__button').addEventListener('click', function(event) {
    let wrapper = event.target;

    for (let i = 0; i < 5; i++) {
      if (wrapper.classList.contains('dsgvo-wrapper')) {
        const type = wrapper.getAttribute('data-type');

        if (type === 'iframe') {
          const src = wrapper.getAttribute('data-src');
          if (src != null && src !== "") {
            const iframe = document.createElement('iframe');
            iframe.src = src;

            if (wrapper.hasAttribute('data-width')) {
              iframe.width = wrapper.getAttribute('data-width');
            }
            if (wrapper.hasAttribute('data-height')) {
              iframe.height = wrapper.getAttribute('data-height');
            }

            wrapper.parentNode.replaceChild(iframe, wrapper);
          } else {
            console.error("DSGVOWrapper: Feld 'src' fehlt für die Ersetzung des Iframes.")
          }
        } else if (type === 'video' || type === 'html') {
          const parent = wrapper.parentNode;
          const html = wrapper.getAttribute('data-html');
          if (html !== null && html !== "") {
            const parser = new DOMParser();
            const embedDocument = parser.parseFromString(html, "text/html");
            embedDocument.body.childNodes.forEach(function(elem, i) {
              if (i === 0) {
                if (type === 'video') {
                  elem.classList.add('dsgvo-wrapper__video');
                }
                parent.replaceChild(elem, wrapper);
              } else {
                parent.style.padding = "0px";
                parent.appendChild(elem);
              }
            });
          } else {
            console.error("DSGVOWrapper: Feld 'video' oder 'html' fehlt für die Ersetzung des Videos.")
          }
        } else {
          console.error("DSGVOWrapper: Für den Inhalts-Typ '" + type + "' ist keine Ersetzung implementiert.");
        }

        break;
      }

      wrapper = wrapper.parentNode;
    }
  });
}
